import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavLinks = (props) => {
  const { onClickHandler } = props;

  const scrollToBottom = () => {
    onClickHandler();
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  const scrollToSection = () => {
    onClickHandler();
    const section = document.getElementById("services");
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 85,
        behavior: "smooth", // Optional: Smooth scrolling animation
      });
    }
  };
  return (
    <>
      <button
        className="menu-text px-4 text-xl text-white hover:text-red-900"
        onClick={scrollToSection}
      >
        Nuestros clubes
      </button>
      <button
        className="menu-text px-4 text-xl text-white hover:text-red-900"
        onClick={scrollToBottom}
      >
        Contáctanos
      </button>
    </>
  );
};

export default NavLinks;
